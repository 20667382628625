<template>
  <main>
    <center-title title="采购清单"></center-title>
         
         <router-link to="/selectmygoodslist" class="el-button whtie submit" 
            >产品浏览</router-link>     
            <router-link to="/shoppingPlan" class="el-button whtie submit"  style="margin-bottom: 2%;"
            >采购清单</router-link>
            <router-link to="/shoppingPlanList" class="el-button whtie submit"  style="margin-bottom: 2%;"
            >采购计划列表</router-link>
       



    <make-breadcrumb
      class="core"
      :breadCrumb="breadCrumb"
      v-if="JSON.stringify(breadCrumb) != '{}'"
    ></make-breadcrumb>
    <article class="bg-white">
      <div
        class="pro-detail core row-between"
        v-if="JSON.stringify(goodsDetail) != '{}'"
      >
        <div class="detail-l">
          <div class="magnifier">
            <!-- 小图 -->
            <div class="magnifier-main row-center">
              <div class="small-box">
                <el-image
                  style="width: 100%; height: 100%"
                  fit="contain"
                  ref="smallBox"
                  class="smallPic"
                  :src="goodsDetail.goods_image[nowCurrent].uri"
                  lazy
                ></el-image>
                <div v-show="topShow" class="top" :style="topStyle"></div>
                <div
                  class="maskTop"
                  @mouseenter="enterHandler"
                  @mousemove="moveHandler"
                  @mouseout="outHandler"
                ></div>
              </div>
            </div>
            <div v-show="rShow" class="right">
              <el-image
                :style="r_img"
                class="rightImg"
                fit="contain"
                :src="goodsDetail.goods_image[nowCurrent].uri"
              >
              </el-image>
            </div>
          </div>
          <div class="swiper-box pr row-bwtween">
            <swiper :options="swiperOption" class="swiper">
              <!-- 轮播的图片 -->
              <swiper-slide
                class="pro-item bg-white"
                v-for="(item, index) in goodsDetail.goods_image"
                :key="index"
                :class="{ active: nowCurrent == index }"
              >
                <el-image
                  style="width: 100%; height: 100%"
                  fit="cover"
                  :src="item.uri"
                  @mouseover="(nowCurrent = index), init()"
                  lazy
                ></el-image>
              </swiper-slide>
              <!-- 左右导航栏 -->
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>
          <div
            class="fav nr row cursor"
            @click="changeStatus"
            :class="goodsDetail.is_collect ? 'primary' : 'lighter'"
          >
            <i class="iconfont md mr5">&#xe60e;</i
            ><span>{{ goodsDetail.is_collect ? "取消收藏" : "收藏宝贝" }}</span>
          </div>
        </div>
        <div class="detail-r">
          <div class="content row-between">
            <div class="content-l">
              <div class="normal bold lg mb20">
                {{ goodsDetail.name }}
              </div>
              <div class="price-box">
                <div class="row-between mb20">
                  <div class="prices row-center">
                    <span class="nr mr20">销售价</span>
                    <div class="" v-if="JSON.stringify(goodsObject) == '{}'">
                      <make-priceformat
                        :price="goodsDetail.min_price"
                        class="mr10"
                        :subscriptSize="20"
                        :firstSize="26"
                        :secondSize="26"
                      ></make-priceformat>
                      <span class="and bold price mr10">-</span>
                      <make-priceformat
                        :price="goodsDetail.max_price"
                        :subscriptSize="20"
                        :firstSize="26"
                        :secondSize="26"
                      ></make-priceformat>
                    </div>
                    <div class="" v-else>
                      <make-priceformat
                        :price="goodsObject.price"
                        class="mr20"
                        :subscriptSize="20"
                        :firstSize="26"
                        :secondSize="26"
                      ></make-priceformat>

                      <make-priceformat
                        :price="goodsObject.market_price"
                        :subscriptSize="14"
                        :firstSize="14"
                        :secondSize="14"
                        :lineThrough="true"
                        color="#999999"
                        >原价</make-priceformat
                      >
                    </div>
                  </div>
                  <div class="comment column-center xs mr20">
                    <span class="warn">累计评价</span>
                    <span class="normal">{{
                      commentCategory[0] ? commentCategory[0].count : 0
                    }}</span>
                  </div>
                </div>
                <div
                  class="row"
                  v-if="
                    goodsDetail.is_reduct == 1 || goodsDetail.is_seckill == 1
                  "
                >
                  <span
                    class="row-center tag nr price mr10"
                    v-if="goodsDetail.is_seckill == 1"
                    >秒杀</span
                  >
                  <div class="row" v-if="goodsDetail.is_reduct == 1">
                    <span class="row-center tag nr price mr10">满减</span>
                    <span
                      class="xs normal"
                      v-for="(item, index) in goodsDetail.full_reduct"
                      :key="index"
                      >满{{ item.full }}减{{ item.reduct }}；</span
                    >
                  </div>
                </div>
              </div>
              <div class="condition xs">
                <div
                  v-if="false"
                  class="condition-item row"
                  :style="addressId ? 'margin-bottom:0;' : ''"
                >
                  <div class="condition-title lighter xs">配送至</div>
                  <div class="condition-info" v-if="addressList.length">
                    <el-select v-model="addressId" placeholder="请选择">
                      <el-option
                        v-for="item in addressList"
                        :key="item.id"
                        :label="item.address"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div
                    class="primary cursor"
                    @click="dialogVisible = true"
                    v-else
                  >
                    新增收货地址
                  </div>
                </div>
                <div class="condition-item row" v-if="addressId">
                  <div class="condition-title"></div>
                  <div class="condition-info">
                    <div class="xs warn">
                      23:00前下单，预计<span class="normal">08月10日(周二)</span
                      >送达，由大雨指定厂家或供应商提供发货和配送服务.
                    </div>
                  </div>
                </div>
          
                <div
                  class="condition-item row text"
                  v-if="false"
                  v-for="(item, index) in goodsSpec"
                  :key="index"
                >
                  <div class="condition-title lighter xs">{{ item.name }}</div>
                  <div class="condition-info">
                    <div class="text-list row wrap">
                      <div
                        class="text-item row-center"
                        v-for="(items, indexs) in item.spec_value"
                        :key="indexs"
                        :class="{ active: items.id == item.nowId }"
                        @click="
                          (item.nowId = items.id), (goodsSpec = [...goodsSpec])
                        "
                      >
                        {{ items.value }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="condition-item row" v-if="type == null">
                  <div class="condition-title lighter xs">购买数量</div>
                  <div class="condition-info">
                    <el-input-number
                      v-model="goodsNum"
                      :min="goodsDetail.min_buy == 0 ? 1 : goodsDetail.min_buy"
                      :max="goodsDetail.stock"
                      class="mr10"
                    >
                    </el-input-number>
                    <span class="warn xs"
                      >件(库存{{ goodsDetail.stock }}件)</span
                    >
                  </div>
                </div>
              </div>
              <div class="btn-box row" v-if="type == null">
                <button
                  class="bg-cartbody cartc mr10 row-center lg"
                  @click="addProcureTemp"
                >
                  <!-- <img src="@/assets/icon/icon-cart2.png" alt="" class="mr10" /> -->
                  <i class="iconfont lg mr10 cartc">&#xe61a;</i>
                  <span>加入采购清单</span>
                </button>
                <!-- <button
                  type="primary"
                  class="lg white now row-center"
                  @click="toBuy"
                >
                  立即购买
                </button> -->
              </div>
            </div>
            <!-- <div v-if="type == null">
              <div class="content-r" v-if="qualityData.length">
                <div class="title md normal row">精品推荐</div>
                <swiper :options="swiperOption1" class="core swiper">
                   轮播的图片 
                  <swiper-slide
                    v-for="(item, index) in qualityData"
                    :key="index"
                  >
                    <store-item :proData="item"></store-item>
                  </swiper-slide>
                  <div class="swiper-button-next" slot="button-next"></div>
                </swiper>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </article>
    <article class="bg-white">
      <div class="detail-info core row-between">

        <div class="info-r">
          <div class="nav-title row nr lighter">
            <div
              class="nav-item row"
              :class="{ active: current == 0 }"
              @click="current = 0"
            >
              商品详情
            </div>
            <div
              class="nav-item row"
              :class="{ active: current == 1 }"
              @click="current = 1"
            >
              累计评价({{ commentCategory[0] ? commentCategory[0].count : 0 }})
            </div>
            <div
              class="nav-item row"
              :class="{ active: current == 2 }"
              @click="current = 2"
              v-if="goodsDetail.safeguard"
            >
              售后保障
            </div>
          </div>
          <div class="info-content">
            <div class="parse" v-if="current == 0">
              <div
                class="attr"
                style="overflow: hidden"
                v-if="JSON.stringify(goodsDetail) !== '{}'"
              >
                <ul>
                  <li>
                    商品编号：{{ goodsDetail.goods_item[0].goods_sncode }}
                  </li>
                  <li>商品品牌：{{ goodsDetail.brand_info.name }}</li>
                  <li>单位：{{ goodsDetail.unit_name }}</li>
                  <li>重量：{{ goodsDetail.goods_item[0].weight }}</li>
                  <li>
                    尺寸：长({{ goodsDetail.goods_item[0].length }}) 宽({{
                      goodsDetail.goods_item[0].width
                    }}) 高({{ goodsDetail.goods_item[0].height }})
                  </li>
                  <li>体积：{{ goodsDetail.goods_item[0].volume }}</li>
                  <li>规格：{{ goodsDetail.goods_item[0].spec_value_str }}</li>

                  <li>商品条码：{{ goodsDetail.goods_item[0].bar_code }}</li>
                </ul>
              </div>
              <div
                v-if="JSON.stringify(goodsDetail) !== '{}'"
                class="content mt30"
                v-html="replaceAllImg(goodsDetail.content)"
                v-lazy-container="{ selector: 'img' }"
              ></div>
            </div>
            <div class="appraise" v-if="current == 1">
              <div class="proportion row">
                <div class="column-center percent">
                  <div class="title normal sm mb10">好评率</div>
                  <div class="price bold num">
                    {{ goodsDetail.comment.percent }}
                  </div>
                </div>
                <div class="plan-list row xxs">
                  <div class="plan-item">
                    <div class="item-bg white">20%</div>
                    <div class="item-name sm lighter">非常不满意</div>
                  </div>
                  <div class="plan-item">
                    <div class="item-bg white">40%</div>
                    <div class="item-name sm lighter">不满意</div>
                  </div>
                  <div class="plan-item">
                    <div class="item-bg white">60%</div>
                    <div class="item-name sm lighter">一般</div>
                  </div>
                  <div class="plan-item">
                    <div class="item-bg white">80%</div>
                    <div class="item-name sm lighter">满意</div>
                  </div>
                  <div class="plan-item">
                    <div class="item-bg white">100%</div>
                    <div class="item-name sm lighter">非常满意</div>
                  </div>
                </div>
              </div>
              <div class="appraise-type row normal">
                <item class="count"></item>
                <div
                  class="type-item row-center"
                  :class="{ active: commentCurrent === index }"
                  @click="commentCurrent = index"
                  v-for="(item, index) in commentCategory"
                  :key="index"
                >
                  {{ item.name }}({{ item.count || 0 }})
                </div>
              </div>
              <div
                class="appraise-list"
                v-if="commentList && commentList.length"
              >
                <div
                  class="appraise-item row"
                  v-for="(item, index) in commentList"
                  :key="index"
                >
                  <div class="item-user row normal sm">
                    <!-- <img src="@/temp/1.jpg" alt="" /> -->
                    <el-image
                      class="avatar"
                      :src="item.avatar"
                      fit="cover"
                    ></el-image>
                    <p class="line1">{{ item.nickname }}</p>
                  </div>
                  <div class="item-info">
                    <div class="info-start row">
                      <el-rate
                        v-model="item.goods_comment"
                        disabled
                        void-color="#dadada"
                        disabled-void-color="#dadada"
                        score-template="{value}"
                      >
                      </el-rate>
                    </div>
                    <p class="info-detail lighter sm">
                      {{ item.comment }}
                    </p>
                    <div class="item-media">
                      <div class="media-list row">
                        <div
                          class="media-item row-center pr"
                          v-for="(items, indexs) in item.image"
                          :key="indexs"
                        >
                          <!-- <img :src="@/temp/2.jpg" alt="" class="item-poster" /> -->
                          <el-image
                            style="width: 52px; height: 52px"
                            :src="items"
                            :preview-src-list="item.image"
                          >
                          </el-image>
                          <!-- <img src="@/assets/icon/icon-play.png" alt="" class="icon-play pa" v-if="index == 0" /> -->
                        </div>
                      </div>
                      <div class="media-box" v-if="false">
                        <img src="@/temp/1.jpg" alt="" />
                        <!-- <video src="" poster="@/temp/2.jpg"></video>/ -->
                      </div>
                      <div class="media-info sm lighter">
                        【{{ item.spec_value_str }}】 {{ item.create_time }}
                      </div>
                    </div>
                    <div class="item-add sm" v-if="item.reply">
                      <p class="add-time mb20">[购买后追评]</p>
                      <p class="add-detail lighter">
                        {{ item.reply }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row-center pagination">
                  <make-pagination
                    class="mt30"
                    @resetList="resetList"
                    :orderObject="orderObject"
                    v-if="commentList.length"
                  >
                  </make-pagination>
                </div>
              </div>
              <el-empty description="暂无评价" v-else></el-empty>
            </div>
            <div
              class="parse"
              v-if="current == 2 && JSON.stringify(goodsDetail) !== '{}'"
              v-html="replaceAllImg(goodsDetail.safeguard)"
              v-lazy-container="{ selector: 'img' }"
            ></div>
          </div>
        </div>
      </div>
    </article>
    <el-dialog
      title="创建地址"
      :visible.sync="dialogVisible"
      width="893px"
      :show-close="false"
      custom-class="address-dialog"
    >
      <add-address
        labelWidth="250"
        @successAddress="getAddressList(), (dialogVisible = false)"
      ></add-address>
    </el-dialog>
  </main>
</template>
<script>
import VueLazyload from "@/utils/vue-lazyload";
import Vue from "vue";
Vue.use(VueLazyload);
import { swiper, swiperSlide } from "vue-awesome-swiper";
import MakeStoreList from "@/components/MakeStoreList";
import CenterTitle from "../components/CenterTitle";
import AddAddress from "../../user/center/components/AddAddress.vue";
import "swiper/dist/css/swiper.css";
import { replaceAllImg } from "@/utils/plugin";
export default {
  name: "",
  props: {},
  components: {
    swiper,
    swiperSlide,
    "make-storeList": MakeStoreList,
    "center-title": CenterTitle,
    "add-address": AddAddress,
  },
  data() {
    return {
      rShow: false,
      topShow: "",
      r_img: {},
      topStyle: { transform: "" },
      replaceAllImg,
      nowCurrent: 0, //当前主图索引
      swiperOption: {
        preventClicks: true,
        slidesPerView: "auto",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOption1: {
        slidesPerView: 2,
        spaceBetween: 0,
        loop: true, // 设置图片循环
        // //左右导航栏
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        direction: "vertical",
      },

      imgObj: {},
      moveLeft: 0,
      moveTop: 0,
      showMagnifier: false,
      showMask: false,
      value: "",
      current: 0,
      proId: null, //产品ID
      goodsDetail: {}, //商品详情
      commentCategory: {}, //商品评论统计
      commentCurrent: 0, //当前商品评论质量索引
      commentList: [], //评论列表
      addressList: [], //地址列表
      addressId: null, //地址ID
      goodsCurrent: 0, //当前商品型号索引
      goodsNum: 1, //购物车数量
      qualityData: [], //精品列表
      goodsList: [], //同类热门列表
      historyList: [], //历史记录列表
      goodsSpec: [], //商品组合列表
      goodsObject: {}, //商品当前对象
      goodsItem: [], //商品组合ID索引存放
      dialogVisible: false,
      breadCrumb: {}, //导航面包屑
      active_type: 0,
      page_no: 1,
      page_size: 10,
      orderObject: {},
      flag: true,
      type: null,
    };
  },
  computed: {},
  watch: {
    // 当前商品评论
    commentCurrent: {
      handler(val) {
        this.getCommentList();
      },
      deep: true,
    },
    goodsSpec: {
      handler(val) {
        let arr = [];
        let index;
        val.forEach((el) => {
          if (el.nowId) arr.push(el.nowId + "");
        });
        index = this.goodsItem.indexOf(arr.join(","));
        if (index != -1) {
          this.goodsObject = this.goodsDetail.goods_item[index];
        }
      },
      deep: true,
    },
  },
  methods: {
    // 鼠标进入原图空间函数
    enterHandler() {
      // 层罩及放大空间的显示
      this.topShow = true;
      this.rShow = true;
    },
    // 鼠标移动函数
    moveHandler(event) {
      // 鼠标的坐标位置
      let x = event.offsetX;
      let y = event.offsetY;
      // 层罩的左上角坐标位置，并对其进行限制：无法超出原图区域左上角
      let topX = x - 75 < 0 ? 0 : x - 75;
      let topY = y - 75 < 0 ? 0 : y - 75;
      // 对层罩位置再一次限制，保证层罩只能在原图区域空间内
      if (topX > 200) {
        topX = 200;
      }
      if (topY > 200) {
        topY = 200;
      }
      // 通过 transform 进行移动控制
      this.topStyle.transform = `translate(${topX}px,${topY}px)`;
      this.r_img.transform = `translate(-${1.75 * topX}px,-${1.75 * topY}px)`;
    },
    // 鼠标移出函数
    outHandler() {
      // 控制层罩与放大空间的隐藏
      this.topShow = false;
      this.rShow = false;
    },
    changeStatus() {
      this.$api
        .changeStatusApi({
          goods_array: [this.proId],
        })
        .then((res) => {
          if (res.code == 1) {
            this.$message({ message: res.msg, type: "success" });
            this.getGoodsDetail();
          }
        });
    },
    handleChange() {},
    async init() {
      await this.$nextTick();
      this.imgObj = this.$el
        .getElementsByClassName("small-box")[0]
        .getBoundingClientRect();
    },
    /**
     * @description 获取商品详情
     * @param {Number} goods_id 商品ID
     * @return void
     */
    async getGoodsDetail() {
      let { data } = await this.$api.getGoodsDetailApi({
        goods_id: this.proId,
      });
      this.goodsDetail = data;
      let category = data.category;
      if (data.brand_info) category.push(data.brand_info);
      this.breadCrumb = {
        detail: true,
        list: category,
      };
      this.goodsSpec = data.goods_spec.map((el) => {
        el.nowId = el.spec_value[0].id;
        return el;
      });
      this.goodsItem = data.goods_item.map((el) => {
        return el.spec_value_ids;
      });
      this.init();
    },

    /**
     * @description 获取同类热销商品
     * @param {Number} platform_cate_id 所属分类ID
     * @returns void
     */
    async getGoodsList() {
      let { code, data } = await this.$api.getGoodsListApi({
        platform_cate_id: this.goodsDetail.third_cate_id,
        sort_by_sales: "desc",
        page_size: 3,
      });
      if (code == 1) {
        this.goodsList = data.lists;
      }
    },
    /**
     * @description 获取商品评论数
     * @returns void
     */
    async getCommentCategory() {
      let { data } = await this.$api.getCommentCategoryApi({
        goods_id: this.proId,
      });
      this.commentCategory = data.comment;
    },
    resetList(e) {
      this.page_no = e;
      this.getCommentList();
    },
    /**
     * @description 获取商品评论列表
     * @param {Number} goods_id 商品ID
     * @param {Number} type 当前评论分类ID
     * @returns void
     */
    getCommentList() {
      this.$api
        .getCommentListApi({
          goods_id: this.proId,
          type: this.commentCategory[this.commentCurrent].id,
          page_no: this.page_no,
          page_size: this.page_size,
        })
        .then((res) => {
          let { list, count, page_size: size, page_no: page } = res.data;
          this.commentList = res.data.lists;
          this.orderObject = res.data;
          this.orderObject = {
            page,
            size,
            count,
            list,
          };
        });
    },
    /**
     * @description 获取地址列表
     * @returns void
     */
    async getAddressList() {
      let { data } = await this.$api.getAddressListApi();
      this.addressList = data;
      if (this.addressList.length) {
        this.addressId = data.filter((el) => {
          return el.is_default == 1;
        })[0].id;
      }
    },
    /**
     * @description 添加至购物车
     * @param {Number} item_id 商品分类项ID
     * @param {Number} goods_num 商品数量
     * @returns void
     */
    addProcureTemp() {
      console.log(this.goodsObject);
      this.$api
        .addProcureTempApi({
          item_id: this.goodsObject.goods_id,
          goods_num: this.goodsNum,
          active_type: this.$route.query?.activity_type || 0,
        })
        .then((res) => {
          if (res.code == 1) {
            // this.$message({ message: res.msg, type: "success" });
            this.$confirm("加入采购清单成功", "", {
              distinguishCancelAndClose: true,
              confirmButtonText: "继续采购",
              cancelButtonText: "查看采购清单",
            })
              .then(() => {})
              .catch((action) => {
                this.$router.push({ path: "/shoppingPlan" });
              });
          }
        });
    },
    /**
     * @description 立即购买
     * @returns void
     */
    toBuy() {
      if (this.goodsNum > this.goodsObject.stock) {
        return this.$message({
          message: "商品库存不足",
          type: "warning",
        });
      }
      // if (this.addressId != 0 && !this.addressId) {
      //   this.$message({
      //     message: "请选择配送地址",
      //     type: "warning",
      //   });
      //   return;
      // }
      let params = {
        data: [
          {
            item_id: this.goodsObject.id,
            num: this.goodsNum,
            goods_id: this.goodsObject.goods_id,
            shop_id: this.goodsDetail.shop_id,
          },
        ],
        active_type: this.$route.query?.activity_type || 0,
        addressId: this.addressId,
      };
      this.$router.push({
        name: "Settle",
        query: {
          data: encodeURIComponent(JSON.stringify(params)),
        },
      });
    },
    /**
     * @description 获取热门/精品推荐列表
     * @param {Number} column_id 1:精品 2:热门
     * @returns void
     */
    async getGoodsListByColumnId() {
      let { code, data } = await this.$api.getGoodsListByColumnIdApi({
        column_id: 1,
        page_size: 10,
        category_id: this.goodsDetail.third_cate_id || "",
      });
      if (code == 1)
        this.qualityData = data.lists.filter((el) => {
          return el.id != this.goodsDetail.id;
        });
    },
    /**
     * @description 获取历史浏览记录
     * @param {Number} page_size 获取条数
     * @returns void
     */
    getSideHistoryLists() {
      this.$api
        .getSideHistoryListsApi({
          page_size: 4,
        })
        .then((res) => {
          if (res.data.length) {
            this.historyList = res.data
              .filter((el) => {
                return el.id != this.proId;
              })
              .slice(0, 3);
          }
        });
    },
  },
  async created() {
    this.type = this.$route.query.type;
    console.log(this.type);

    this.proId = this.$route.query.id;
    await this.getGoodsDetail();
    await this.getCommentCategory();
    this.getCommentList();
    // this.getAddressList();
    this.getGoodsListByColumnId();
    this.getGoodsList();
    this.getSideHistoryLists();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.pro-detail {
  padding-top: 20px;
  padding-bottom: 40px;
  align-items: flex-start;

  .detail-l {
    .magnifier {
      margin-bottom: 20px;
      position: relative;
      width: 350px;
      height: 350px;
      border: $-solid-border;
      box-sizing: content-box;

      .maskTop {
        width: 350px;
        height: 350px;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
      }

      .top {
        width: 150px;
        height: 150px;
        background-color: #43aae9;
        opacity: 0.4;
        position: absolute;
        top: 0;
        left: 0;
      }

      .right {
        margin-left: 370px;
        width: 350px;
        height: 350px;
        border: $-solid-border;
        box-sizing: content-box;
        background-color: white;
        overflow: hidden;
        position: absolute;
        top: 0;
        z-index: 99;
      }

      .rightImg {
        display: inline-block;
        width: 700px;
        height: 700px;
        position: absolute;
        top: 0;
        left: 0;
      }

      .magnifier-main {
        width: 100%;
        height: 100%;
        text-align: center;

        .small-box {
          position: relative;
          width: 100%;
          margin: 0 auto;
          height: 100%;
          display: grid;

          .magnifier-zoom {
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }

      .magnifier-layer {
        position: absolute;
        top: 0;
        right: 0;
        width: 350px;
        height: 350px;
        z-index: 1000;
        border: $-solid-border;
        overflow: hidden;
        background-color: #fff;

        .big-box {
          position: absolute;
        }
      }
    }

    .swiper-box {
      margin-bottom: 15px;

      /deep/ .swiper {
        width: 280px;
        position: initial;

        .swiper-button-prev,
        .swiper-button-next {
          width: 20px;
          height: 34px;
          background-size: 20px 34px;
          background-image: url("~@/assets/icon/icon-arrow5.png");
          margin-top: 0;
          right: 0;
          margin-top: -13px;
        }

        .swiper-button-prev {
          transform: rotate(180deg);
          left: 0;
        }

        .pro-item {
          width: 68px;
          height: 68px;
          display: inline-block;
          position: relative;
          padding: 2px;

          &:not(.active) {
            transform: scale(0.8);
          }

          &.active {
            box-sizing: border-box;

            &::before {
              content: "";
              position: absolute;
              top: 2px;
              left: 2px;
              right: 2px;
              bottom: 2px;
              // width: 100%;
              // height: 100%;
              border: 2px solid $-color-price;
              z-index: 9;
            }
          }
        }
      }
    }

    .fav {
      line-height: 1;

      img {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
    }
  }

  .detail-r {
    width: 830px;

    .content {
      align-items: flex-start;

      .content-l {
        width: 590px;
        flex-shrink: 0;

        .price-box {
          padding: 20px;
          margin-bottom: 15px;
          background: #fafafa;
          box-shadow: 0px 0px 6px 0px rgba(51, 51, 51, 0.2);

          .comment {
            padding-left: 10px;
            height: 28px;
            border-left: $-solid-border;
          }

          .tag {
            width: 60px;
            height: 24px;
            border: 1px solid $-color-price;
            border-radius: 4px;
            line-height: 24px;
          }

          .and {
            font-size: 26px;
          }
        }

        .condition {
          .condition-item {
            margin-bottom: 15px;

            &:last-child {
              margin-bottom: 0;
            }

            &.text {
              margin-top: -10px;
            }

            .condition-title {
              width: 90px;
              padding: 0 15px;
              flex-shrink: 0;
              text-align: justify;
              height: 40px;
              line-height: 13px;

              &:before,
              &:after {
                content: "";
                padding-left: 100%;
                display: inline-block;
                height: 0;
              }
            }

            .condition-info {
              /deep/ .el-select {
                .el-input__inner {
                  height: 28px;
                  border: 1px solid #999999;
                  border-radius: 0;
                }

                .select__caret {
                  line-height: 28px;
                }

                .el-input__icon {
                  line-height: inherit;
                }

                .el-select-dropdown__item.selected {
                  color: $-color-primary;
                }
              }

              /deep/ .el-input-number {
                width: 85px;
                line-height: 24px;

                .el-input-number__decrease:hover:not(.is-disabled)
                  ~ .el-input
                  .el-input__inner:not(.is-disabled),
                .el-input-number__increase:hover:not(.is-disabled)
                  ~ .el-input
                  .el-input__inner:not(.is-disabled) {
                  border-color: #dcdfe6;
                }

                .el-input-number__decrease,
                .el-input-number__increase {
                  width: 22px;
                  height: 22px;
                  background: #f28101;
                  color: white;
                  border-radius: 0;

                  &.is-disabled {
                    background-color: #f2f2f2 !important;
                    color: #cccccc !important;
                  }
                }

                .el-input-number__increase {
                  right: 0;
                }

                .el-input__inner {
                  height: 24px;
                  line-height: 24px;
                  border-radius: 0;
                  padding: 0;
                }
              }

              .color-list {
                img {
                  width: 40px;
                  height: 40px;
                  display: block;
                  margin-right: 10px;
                  margin-top: 10px;
                  cursor: pointer;

                  &:nth-child(-n + 10) {
                    margin-top: 0;
                  }
                }
              }

              .text-list {
                margin-top: -10px;

                .text-item {
                  padding: 0 20px;
                  height: 26px;
                  background: #f2f2f2;
                  margin-right: 10px;
                  margin-top: 10px;
                  box-sizing: border-box;
                  border: 1px solid #f2f2f2;
                  cursor: pointer;

                  &.active {
                    background: #ffffff;
                    border: 1px solid #f28101;
                  }
                }
              }
            }
          }
        }

        .btn-box {
          padding-left: 90px;
          margin-top: 40px;

          button,
          .now {
            height: 47px;
            padding: 0 28px;
            cursor: pointer;
          }

          > .cartc {
            border: 2px solid $-color-cartc;
          }

          .now {
            background-color: $-color-cartc;

            &:hover {
              color: white !important;
            }
          }
        }
      }

      .content-r {
        width: 218px;
        border: $-solid-border;
        padding: 0 10px 30px;
        position: relative;

        .title {
          height: 45px;
          border-bottom: $-solid-border;
        }

        .swiper {
          width: 198px;
          height: 448px;
          position: initial;
          margin: 15px 0;
        }

        .swiper-button-prev,
        .swiper-button-next {
          bottom: 0;
          top: auto;
          left: 0;
          right: 0;
          width: 100%;
          height: 40px;
          background: #f8f8f8;
          background-image: url("~@/assets/icon/icon-arrow6.png");
          background-size: 31px 11px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
}

.detail-info {
  align-items: flex-start;
  padding-bottom: 10px;

  .info-l {
    width: 218px;

    .pro-list {
      .pro-item {
        border: $-solid-border;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        .item-title {
          height: 46px;
          background: #f3f5f8;
          padding-left: 18px;
        }

        .store-list {
          padding-top: 20px;
        }
      }
    }
  }

  .info-r {
    width: 958px;
    border: $-solid-border;
    border-top: none;

    .nav-title {
      border-top: $-solid-border;
      height: 46px;
      background: #f3f5f8;

      .nav-item {
        padding: 0 30px;
        height: 100%;
        cursor: pointer;
        position: relative;

        &.active {
          background-color: $-color-white;

          &::before {
            content: "";
            position: absolute;
            top: -1px;
            left: 0;
            right: 0;
            height: 2px;
            background-color: $-color-primary;
          }
        }
      }
    }

    .info-content {
      .parse {
        padding: 20px;

        /deep/ img {
          display: block;
          margin: 0 auto;
          max-width: 100%;
        }

        .attr {
          ul {
            margin-bottom: 20px;

            &:after,
            &:before {
              clear: both;
              content: "";
            }

            li {
              width: 250px;
              //padding-left: 42px;
              float: left;
              margin-bottom: 5px;
            }
          }
        }
      }

      .appraise {
        padding: 35px 40px;

        .proportion {
          align-items: flex-start;
          margin-bottom: 30px;

          .percent {
            width: 150px;
            padding-right: 40px;
            border-right: $-solid-border;
            margin-right: 40px;

            .num {
              font-size: 39px;
            }
          }

          .plan-list {
            .plan-item {
              text-align: right;

              &:nth-child(1) {
                background: #fceceb;
              }

              &:nth-child(2) {
                background: #f8c6c4;
              }

              &:nth-child(3) {
                background: #f4a09d;
              }

              &:nth-child(4) {
                background: #ee6763;
              }

              &:nth-child(5) {
                background: #f43c37;
              }

              .item-bg {
                width: 104px;
                height: 16px;
                padding-right: 5px;
              }

              .item-name {
                padding-top: 5px;
                background-color: white;
              }
            }
          }
        }

        .appraise-type {
          .type-item {
            height: 46px;
            padding: 0 20px;
            margin-right: 12px;
            min-width: 118px;
            background: #f3f5f8;
            cursor: pointer;

            &.active {
              background-color: #f43c37;
              color: white;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .appraise-list {
          .appraise-item {
            padding-top: 35px;
            padding-bottom: 60px;
            align-items: flex-start;
            border-bottom: $-solid-border;

            .item-user {
              width: 115px;
              flex-shrink: 0;
              padding-right: 10px;

              .avatar {
                width: 30px;
                height: 30px;
                min-width: 30px;
                margin-right: 9px;
                border-radius: 50%;
              }
            }

            .item-info {
              width: 735px;

              .info-start {
                height: 30px;

                /deep/ .el-rate__icon {
                  margin-right: 0px;
                }
              }

              .info-detail {
                line-height: 28px;
                margin-bottom: 10px;
              }

              .item-media {
                padding-bottom: 20px;
                border-bottom: $-solid-border;

                .media-list {
                  margin-bottom: 15px;

                  .media-item {
                    width: 52px;
                    height: 52px;
                    margin-right: 5px;
                    vertical-align: middle;
                    cursor: pointer;
                    border: $-solid-border;

                    &:last-child {
                      margin-right: 0;
                    }

                    .item-poster {
                      max-width: 100%;
                      max-height: 100%;
                    }

                    .icon-play {
                      width: 20px;
                      height: 20px;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                      z-index: 10;
                      border-radius: 50%;
                      background-color: rgba(51, 51, 51, 0.7);
                    }

                    &:hover {
                      border-color: $-color-price;

                      &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(51, 51, 51, 0.1);
                        z-index: 2;
                      }
                    }
                  }
                }

                .media-box {
                  width: 418px;
                  margin-bottom: 10px;

                  img,
                  video {
                    width: 100%;
                    height: auto;
                  }
                }
              }

              .item-add {
                padding-top: 10px;

                .add-time {
                  color: #bababa;
                }

                .add-detail {
                  line-height: 1.5;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
